import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/wx") });

interceptor.auth(axios);

export function getWxConfig(appId, data) {
  return axios.post(`${appId}/jsapiSignature`, data);
}
