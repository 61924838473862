import Widget from "@/views/datamodel/components/dashboard/src/models/widget";

export function createResponsiveLayouts(layout) {
  // y 小者置于首位；y 相同者，其 x 小者置于首位
  layout.sort((a, b) => {
    return a.y - b.y || a.x - b.x;
  });
  const lg = layout.map(Widget.from);
  let sumY = 0;
  const xxs = lg.map(item => {
    const widget = Widget.from(item);
    widget.x = 0;
    widget.y = sumY;
    widget.w = 1;
    sumY += widget.h;
    widget.options = item.options;
    widget.datasource = item.datasource;
    return widget;
  });
  return { lg, xxs };
}
