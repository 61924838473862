<template>
  <div>
    <Layout
      readonly
      :layout="layout"
      :grid-props="{
        responsiveLayouts,
        responsive: true,
        cols: { lg: 24, md: 24, sm: 24, xs: 24, xxs: 1 }
      }"
      @breakpoint-changed="onBreakpointChanged"
    />
  </div>
</template>

<script>
import Axios from "axios";
import * as API from "@/api/share";
import * as WX from "@/api/wx";
import Layout from "@/views/datamodel/components/dashboard/src/layout";
import { createResponsiveLayouts } from "./utils";

export default {
  components: { Layout },
  data() {
    return {
      responsiveLayouts: { lg: [], xxs: [] },
      layout: [],
      tableName: {}
    };
  },
  async beforeRouteEnter(to, from, next) {
    const sharedId = to.params.dashboardId;
    try {
      const { data } = await API.getDashboardLayout(sharedId);

      // 为数据源添加 sharedId 属性
      for (const item of data.layout) {
        if (item.datasource) {
          item.datasource.sharedId = sharedId;
        }
      }
      next(vm => {
        const responsiveLayouts = createResponsiveLayouts(data.layout);
        vm.tableName = data.name;
        vm.layout = responsiveLayouts.lg;
        vm.responsiveLayouts = responsiveLayouts;
      });
    } catch {
      next({ name: "share-404" });
    }
  },
  methods: {
    onBreakpointChanged(breakpoint) {
      const layouts = this.responsiveLayouts;
      this.layout = breakpoint === "xxs" ? layouts.xxs : layouts.lg;
    },
    async wxaccess() {
      let appidData = await this.getAppid();

      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
      document.body.appendChild(script);

      let url = window.location.href;
      let appid = appidData[location.host];
      let params = {
        url: url
      };
      const { data } = await WX.getWxConfig(appid, params);
      let that = this;
      window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "hideMenuItems"
        ] // 必填，需要使用的JS接口列表
      });
      window.wx.ready(function() {
        //需在用户可能点击分享按钮前就先调用
        window.wx.updateAppMessageShareData({
          title: that.tableName.name || "下秒数据看板", // 分享标题
          desc:
            "数据集成,SaaS集成,数据融合,API集成,数据管道,反向ETL,数据应用自动化,数据治理,数据对接,供应链集成,OMS集成,ERP集成,下秒数据,Nexadata", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "../../../../public/favico/favicon.ico", // 分享图标
          success: function() {
            // 设置成功
          }
        });
        //需在用户可能点击分享按钮前就先调用
        window.wx.updateTimelineShareData({
          title: "下秒数据Nexadata", // 分享标题
          desc:
            "数据集成,SaaS集成,数据融合,API集成,数据管道,反向ETL,数据应用自动化,数据治理,数据对接,供应链集成,OMS集成,ERP集成,下秒数据,Nexadata", // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "../../../../public/favico/favicon.ico", // 分享图标
          success: function() {
            // 设置成功
          }
        });
        window.wx.hideMenuItems({
          menuList: ["menuItem:share:appMessage", "menuItem:share:timeline"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
        });
      });
    },
    async getAppid() {
      const { data } = await Axios.get("./files/appidData.json");
      return data;
    }
  },
  mounted() {
    this.wxaccess();
  }
};
</script>
